import Loading from "vue-loading-overlay";
export default {
    name: "Location",
    components: {
        Loading,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            isShow: false,
            mapLink: "",
            studioList: [],
            componentUi: {},
            openedMarkerID: null,
            zoomValue: 5,
            center: {},
            markers: []
            // center: { lat: 51.093048, lng: 6.842120 },
            // markers: [
            //     {
            //         position: {
            //             lat: 51.093048, lng: 6.842120
            //         },
            //     }
            // ]
        }
    },
    async created() {
        await this.getPageTitle();
        document.title = (this.pageTitle?.locationPageTitle ? this.pageTitle?.locationPageTitle : '');
        await this.getStudioList();
    },

    methods: {
        openMarker(id) {
            this.openedMarkerID = id
        },
        getStudioList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.studio.requestURL,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.markers = [];
                    this.studioList = response.data.studio;
                    this.componentUi = response.data.locationUIComponent;
                    if (this.studioList.length > 0) {
                        if (this.studioList[0].lat && this.studioList[0].lng) {
                            this.center = { lat: parseFloat(this.studioList[0].lat), lng: parseFloat(this.studioList[0].lng) };
                        } else {
                            this.center = { lat: 41.70753939999999, lng: -86.8950297 };
                        }
                        this.studioList.map(el => {
                            this.markers.push({
                                id: el.id,
                                position: {
                                    lat: parseFloat(el.lat), lng: parseFloat(el.lng)
                                },
                                studioName: el.studioName,
                                locationName: el.locationName,
                            })
                        })
                    }
                }

            }).catch(error => {
                console.log(error);
            });
        },
        setMapLink: function (data) {
            this.center = { lat: parseFloat(data.lat), lng: parseFloat(data.lng) };
            this.zoomValue = 15;
        }

    }
}
