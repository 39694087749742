<template>
  <div class="bg-about-gradient">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="about-section py-3 py-md-0 d-flex flex-column flex-md-row">
      <div class="w-100 h-100 p-5 d-flex align-items-center">
        <div class="py-2 py-sm-0">
          <h2 class="about-section-heading" :style="{color:componentUi.aboutTitleFontColor}">
            {{aboutData.pageTitle}}
            <br />
            {{aboutData.pageSubTitle}}
          </h2>
          <p
            class="about-section-text"
            :style="{color:componentUi.aboutSubTitleFontColor}"
          >{{aboutData.pageDescription}}</p>
        </div>
      </div>
      <div class="w-100 h-100 about-section-right" :style="{'max-height': '680px', 'max-width': '512px', 'margin-top': '10%'}">
        <img :src="$imageURL + 'about-page-cms/' + aboutData.pageImage" class="w-100 h-100" />
      </div>
    </div>
  </div>
</template>

<script src="../js/about.js">
</script>

<style scoped>
@import "../css/about.css";
</style>
