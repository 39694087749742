<template>
  <section
    class="full_page three position-relative bg-site-gray z-index-2"
    v-if="sectionThree.length > 0"
  >
    <div class="custom-container-fluid h-100">
      <h1 class="section_three_heading" :style="{color:componentUi.sectionThreeHeaderFontColor}">{{sectionThreeHeader}}</h1>
      <div class="row gy-4 mt-3">
        <div class="col-12 col-sm-6 col-md-4" v-for="(data, index) in sectionThree" :key="index">
          <div
            class="section_three_card h-100"
            :style="{

'background-image': `url(${$imageURL}section-three-cms/${data.image})`,

}"
          >
            <img :src="$imageURL + 'section-three-cms/' + data.logo" class="section_three_card_img" />
            <div class="section_three_card_title" :style="{color:componentUi.sectionThreeTitleFontColor}">{{data.title}}</div>
            <div>
              <p class="section_three_card_text" :style="{color:componentUi.sectionThreeSubTitleFontColor}">{{data.subTitle}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom_pagination">
      <div class="custom-container-fluid">
        <div class="d-flex align-items-center gap-4">
          <div class="d-flex text-site-dark h-100">
            <span class="active_page h-100 align-self-start">003</span>
            <span class="mx-2 h-100 align-self-start">/</span>
            <span class="h-100 align-self-start">007</span>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void(0)" @click="scrollToBottom" class="btn arrow-down" id="p3">
      <i class="bi bi-arrow-down"></i>
    </a>
  </section>
</template>

<script src="../js/section_three.js">
</script>

<style scoped>
@import "../css/section_three.css";
</style>
