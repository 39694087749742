import { store } from "@/store";
export default {
    components: {},
    data() {
        return {
            store,
            footerCms: {},
            socialLinks: []
        }
    },
    async created() {
        await this.getCmsData();
        await this.getSocialLinks();
    },
    methods: {
        getCmsData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.footer.footerCmsURL,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200 && response.data.data.length > 0) {
                    this.footerCms = response.data.data[0];
                } else {
                    this.footerCms = {};
                }
            }).catch(error => {
                console.log(error);
            });
        },
        async goHome() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
            await this.$router.push('/')
        },
        getSocialLinks: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.footer.socialLinks,
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    if(Object.values(response.data.data).length>0){
                        this.socialLinks = response.data.data?.data.length>0 ?  response.data.data?.data : [];
                    }else{
                        this.socialLinks = []
                    }
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
            });
        },
    },

};
