<template>
  <div :style="{'background-color':componentUi.pageBackgroundColor, 'margin-top':'80px'}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid py-5 mt-5">
      <div
        class="d-flex gap-3 flex-column flex-sm-row justify-content-between align-items-center mb-4"
      >
        <h2
          class="ff-poppins fw-600 fs-23 mb-0"
          :style="{color: componentUi.headerFontColor}"
        >Sub Services</h2>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group"></div>
      </div>
      <div class="row row-cols-1 row-cols-md-4 g-4" v-if="subServiceList.length > 0">
        <div class="col" v-for="(item, index) in subServiceList" :key="index">
          <div
            class="card"
            @click="$router.push(`/service-professionals/${item.id}`)"
            style="cursor:pointer"
          >
            <img
              :style="{ height: '300px' ,borderBottom: '1px solid grey'}"
              :src="$imageURL + 'service-sub-category/' + item.subCategoryImage"
              alt="image"
              class="card-img-top img-fluid"
            />
            <div class="card-body">
              <h5 class="card-title text-center ff-poppins fw-600 fs-20">{{ item.subCategoryName }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info" role="alert" style="text-align:center;">No Data Found!</div>
      </div>
    </div>
  </div>
</template>
<script src="../js/sub-service.js">
</script>