import Loading from 'vue-loading-overlay';
export default {
  components: {
    Loading
  },
  name: "Signup",
  data(){
    return{
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      userType: null
    }
  },
  async created() {
    await this.getPageTitle();
    document.title = (this.pageTitle?.registrationPageTitle ? this.pageTitle?.registrationPageTitle : '');
  },
  methods: {
  }
}
