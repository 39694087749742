import { createRouter, createWebHashHistory } from "vue-router";
import Signup from "../components/auth/template/Signup";
import ActiveAccount from "../components/auth/template/ActiveAccount";

import Signin from "../components/auth/template/Signin";
// import ChooseUser from "../components/auth/template/ChooseUser";
import ForgotPassword from "../components/auth/template/ForgotPassword";
import ResetPassword from "../components/auth/template/ResetPassword";
import serviceProfessional from "../components/serviceProfessional/template/serviceProfessional.vue";
import Home from "../components/landing_page/home/template/Home";
import Service from "../components/service/template/Service";
import SubService from "../components/service/template/SubService"
import About from "../components/about/template/About";
import Contact from "../components/contact/template/Contact";
import Experts from "../components/experts/template/Experts";
import Location from "../components/location/template/Location";
//import guard from "../guard";

let routes = [
  { path: "/choose-user-signup", name: "Signup", component: Signup },
  { path: "/choose-user-signin", name: "Signin", component: Signin },
  //   { path: "/choose-user", name: "ChooseUser", component: ChooseUser },
  { path: "/active-account", name: "ActiveAccount", component: ActiveAccount },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  { path: "/reset-password", name: "ResetPassword", component: ResetPassword },

  { path: "/", redirect: "/home" },
  { path: "/home", name: "Home", component: Home },
  { path: "/professional-services", name: "Service", component: Service },
  { path: "/sub-services/:id", name: "SubService", component: SubService },
 
  { path: "/about", name: "About", component: About },
  { path: "/contact", name: "Contact", component: Contact },
  { path: "/experts", name: "Experts", component: Experts },
  { path: "/location", name: "Location", component: Location },
  {
    path: "/service-professionals/:id",
    name: "serviceProfessional",
    component: serviceProfessional,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
