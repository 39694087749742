import Loading from "vue-loading-overlay";
export default {
    name: "ResetPassword",
    components:{
        Loading
    },
    data(){
        return{
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            showPassword: false,
            showRepeatPassword: false,
            email: 'merchant@email.com',
            password: '',
            repeatPassword: '',

        }
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleShowRepeat() {
            this.showRepeatPassword = !this.showRepeatPassword;
        },
        resetPassword(){
            console.log(this.password)
        }
    }
}