<template>
  <footer class="bd-footer">
    <div class="container py-4 py-md-5 px-4 px-md-3">
      <div class="row">
        <div class="col-lg-4 mb-3">
          <a
            class="d-inline-flex fs-1 fw-900 text-site-dark align-items-center mb-2 text-decoration-none"
            @click="goHome"
          >{{footerCms.title}}</a>
          <ul class="list-unstyled small text-muted">
            <li class="mb-2">
              <p>
                <strong v-html="footerCms.description"></strong>
              </p>
            </li>
            <li class="mb-1 mt-3">
              <label>Email:</label>
              {{footerCms.email}}
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-lg-2 offset-lg-1 mb-3">
          <h5 class="ff-montserrat500 mb-5">Quick Links</h5>
          <ul class="list-unstyled ff-poppins text-site-dark fw-500">
            <li class="mb-1">
              <a class="text-site-dark fs-14 cp text-capitalize" @click="$router.push('/')">HOME</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-dark fs-14 cp text-capitalize"
                @click="$router.push('/about')"
              >ABOUT</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-dark fs-14 cp text-capitalize"
                @click="$router.push('/professional-services')"
              >PROFESSIONAL SERVICES</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-dark fs-14 cp text-capitalize"
                @click="$router.push('/location')"
              >COSMO STUDIOS</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-dark fs-14 cp text-capitalize"
                @click="$router.push('/experts')"
              >COSMO EXPERTS</a>
            </li>
            <li class="mb-1">
              <a
                class="text-site-dark fs-14 cp text-capitalize"
                @click="$router.push('/contact')"
              >CONTACT</a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 col-lg-2 mb-3">
          <h5 class="ff-montserrat500 mb-5">Social Pack</h5>
          <ul class="list-unstyled ff-poppins text-site-dark">
            <li class="mb-1" v-for="(data, index) in socialLinks" :key="index">
              <a class="text-site-black fs-14 text-danger" :href="data.siteLink" target="_blank">
                <i :class="[data.siteIconClass, 'me-2']"></i>
                {{data.title}}
              </a>
            </li>
            <!-- <li class="mb-1">
              <a class="text-site-black fs-14 text-primary" href target="_blank">
                <i class="bi bi-facebook"></i>
                facebook
              </a>
            </li>
            <li class="mb-1">
              <a class="text-site-black fs-14 text-site-dark" href target="_blank">
                <i class="bi bi-instagram me-2"></i>
                instagram
              </a>
            </li> -->
          </ul>
        </div>
        <div class="col-12 col-md-4 col-lg-3 mb-3">
          <h5 class="ff-montserrat700 mb-5">Subscribe</h5>
          <div class="input-group mb-3">
            <input
              type="email"
              class="form-control shadow-none"
              :placeholder="footerCms.emailPlaceholderText"
              aria-label="Enter your email"
              aria-describedby="button-addon2"
            />
            <button class="btn btn-outline-secondary shadow-none" type="button" id="button-addon2">
              <i class="bi bi-send"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="footer-div d-flex justify-content-center mt-4" v-html="footerCms.copyrightText">
        
      </div>
      <!-- <p
          class="mb-0 ff-montserrat300 align-self-end order-2 order-sm-1">Copyright ©
          <span
          style="text-decoration: none; color: rgb(0, 0, 0); font-weight: bold;"> Cosmo-Salon</span> | ( Built by
        <a target="_blank" href="https://www.neutrix.co"
           style="text-decoration: none; color: rgb(0, 0, 0); font-weight: bold;">Neutrix</a> - Powered by <a
            target="_blank" href="https://www.neutrix.systems"
            style="text-decoration: none; color: rgb(0, 0, 0); font-weight: bold;">Neutrix Systems</a> )
      </p>-->
    </div>
  </footer>
</template>

<script src="../js/footer.js"></script>

<style scoped>
 a {
  color: black !important;
}
</style>
