import Loading from "vue-loading-overlay";
import VuePictureSwipe from 'vue3-picture-swipe';
export default {
    name: "Sub Services",
    data() {
        return {
            id:this.$route.params.id,
            subServiceList:[],
            componentUi:{}
        }
    },
    components: {
        Loading,
        VuePictureSwipe
    },
    async created() {
        await this.getPageTitle();
        document.title = (this.pageTitle?.professionalSubServicePageTitle ? this.pageTitle?.professionalSubServicePageTitle : '');
        this.getSubServices();
        this.getComponentUi();
    },
    methods:{
        getSubServices: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.subCategory.requestURL + '/' + this.id,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.subServiceList = response.data.data
                }

            }).catch(error => {
                console.log(error);
            });
        },
        getComponentUi: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.professionals.subServiceComponentUiURL,
            }
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.componentUi = response.data.data.length > 0 ? response.data.data[0] : {}
                }
            })
        }
    }
    
}
