export default {
    data(){
        return{
            pageTitle: {},
        }
    },
    methods: {
        getPageTitle: async function(){
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.pageTitleCMS.pageTitleCMSURL
                };
                await this.$axios(config).then(async(response) => {
                    if (response.data.statusCode == 200) {
                        this.pageTitle = response.data.data.length > 0 ? response.data.data[0] : {};
                    }
                }).catch (error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error);
            }
        },
    },
}