<template>
  <section class="full_page six position-relative z-index-2" :data-visual-label="sectionSix.backgroundTitle" v-if="Object.values(sectionSix).length > 0">
    <div class="custom-container-fluid h-100">
      <div class="d-flex gap-4 h-100 flex-column flex-sm-row align-items-center">
        <div class="w-100">
          <h1 class="section_six_heading" :style="{color:componentUi.sectionSixTitleFontColor}">{{sectionSix.title}}</h1>
          <p class="section_six_text fs-md-18 fs-sm-16" :style="{color:componentUi.sectionSixSubTitleFontColor}">{{sectionSix.description}}</p>
          <button class="btn section_six_btn px-4"><a :href="sectionSix.buttonLink" target="_blank" style="color:inherit;">{{sectionSix.buttonText}}</a></button>
        </div>
        <div class="w-100 h-100 d-grid align-item center">
          <img class="w-100 my-auto" :src="$imageURL + 'section-six-cms/' + sectionSix.image">
        </div>
      </div>
    </div>
    <div class="custom_pagination">
      <div class="custom-container-fluid">
        <div class="d-flex align-items-center gap-4">
          <div class="d-flex text-white h-100">
            <span class="active_page h-100 align-self-start">006</span>
            <span class="mx-2 h-100 align-self-start">/</span>
            <span class="h-100 align-self-start">007</span>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void(0)" @click="scrollToBottom" class="btn arrow-down" id="p6">
      <i class="bi bi-arrow-down"></i>
    </a>
  </section>
</template>

<script src="../js/section_six.js">
</script>

<style scoped>
@import "../css/section_six.css";
</style>
