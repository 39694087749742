<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div v-if="isLoginPage === false" class="content-container">
      <Header></Header>
        <router-view></router-view>
      <Sidebar></Sidebar>
      <Footer v-if="isHome"/>
    </div>
    <router-view v-if="isLoginPage === true"></router-view>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import Header from "./components/layouts/template/Header";
import Sidebar from "./components/layouts/template/Sidebar";
import Footer from "./components/layouts/template/Footer";
import { store } from "./store";
export default {
  name: "App",
  components: {
    Loading,
    Header,
    Sidebar,
    Footer
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      isLoading: false,
      fullPage: true,
      loader: "bars",
      isLoginPage: false,
      store,
      isHome: true
    };
  },
  created() {
    if (
      this.$route.path == "/signin" ||
      this.$route.path == "/signup" ||
      this.$route.path == "/active-account" ||
      this.$route.path == "/forgot-password" ||
      this.$route.path == "/reset-password"
    ) {
      if (this.token) {
        this.isLoginPage = false;
        this.$router.push("/home");
      } else if (!this.token) {
        this.isLoginPage = true;
      }
      this.isLoginPage = true;
    } else if (this.$route.path === "/" || this.$route.path === "/home"){
      this.isHome = false;
    } else {
      this.isHome = true;
      this.isLoginPage = false;
    }
  },
  methods: {},
  watch: {
    $route() {
      if (
        this.$route.path == "/signin" ||
        this.$route.path == "/signup" ||
        this.$route.path == "/active-account" ||
        this.$route.path == "/forgot-password" ||
        this.$route.path == "/reset-password"
      ) {
        if (this.token) {
          this.isLoginPage = false;
          this.$router.push("/home");
        } else if (!this.token) {
          this.isLoginPage = true;
        }
        this.isLoginPage = true;
      } else if (this.$route.path === "/" ||
          this.$route.path === "/home" ||
          this.$route.path === "/contact" ||
          this.$route.path === "/location"
      ){
        this.isHome = false;
      } else {
        this.isHome = true;
        this.isLoginPage = false;
      }
    },
  },
};
</script>

<style>
@import "assets/css/all.min.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "assets/css/common.scss";
@import "assets/css/vue-loading.scss";
</style>
