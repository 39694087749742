<template>
  <div class="forgot_page">
    <loading :active='isLoading' :is-full-page="fullPage" :loader='loader'/>
    <div class="forgot_page_form_section">
      <div class="card forgot_form_card">
        <div class="forgot_form_card_title">
          <h3>Enter Your Email</h3>
        </div>
        <form class="ps-4 pe-4" v-on:submit.prevent="reset">
          <div class="mb-4">
            <label for="email" class="form-label login_form_card_label">Email Address</label>
            <div class="position-relative">
              <input type="email" v-model="email" class="form-control forgot_page_custom_input" id="email"
                     placeholder="Enter your email address" required>
              <i class="bi bi-envelope forgot_page_custom_input_icon"></i>
            </div>
          </div>
          <div class="form-group">
            <input
                type="submit"
                class="login_form_card_login_btn primary_btn"
                value="Send"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script src="../js/ForgotPassword.js"></script>

<style scoped>
@import "../../../assets/css/forgot_password.scss";
</style>