import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  name: "Breakpoints",
  props: ["sectionFive", "review", "componentUi"],
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      name: "barber",
      store: "shop",
      settings: {
        itemsToShow: 1,
        snapAlign: "start",
        wrapAround: true,
        autoplay: 0,
        pauseAutoplayOnHover: true,
      },
      breakpoints: {
        // 700px and up
        575: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 2.7,
          snapAlign: "start",
        },
      },
    };
  },
  methods: {
    scrollToBottom() {
      console.log("Scrolling to top");
      document.querySelector("#p5").scrollIntoView({ behavior: "smooth" });
    },
  },
};
