import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment';
import Toaster from '@meforma/vue-toaster';
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap';
import vue3StarRatings from "vue3-star-ratings";
import mixin from './mixin'
import VueGoogleMaps from '@fawmi/vue-google-maps';
const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$active = false;
app.config.globalProperties.$tokenKey = 'token';
app.config.globalProperties.$api = require('../config/api.json');
app.config.globalProperties.$filters = {
    formatDate(date) {
        return moment(String(date)).format('DD/MM/YYYY hh:mm:ss A');
    }
};




const application = require('../config/application.json');


if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    app.config.globalProperties.$hostURL = application.staging.HOST;
    app.config.globalProperties.$serverURL = application.staging.SERVER_HOST;
    app.config.globalProperties.$imageURL = application.staging.IMAGE_PATH;
}
else {
    app.config.globalProperties.$hostURL = application.server.HOST;
    app.config.globalProperties.$serverURL = application.server.SERVER_HOST;
    app.config.globalProperties.$imageURL = application.server.IMAGE_PATH;
}

app.config.globalProperties.$clientSignInURL = application.URL.CLIENT_SIGN_IN_URL;
app.config.globalProperties.$professionalSignInURL = application.URL.PROFESSIONAL_SIGN_IN_URL;
app.config.globalProperties.$clientSignUpURL = application.URL.CLIENT_SIGN_UP_URL;
app.config.globalProperties.$professionalSignUpURL = application.URL.PROFESSIONAL_SIGN_UP_URL;
app.config.globalProperties.$professional_profile = application.URL.professional_profile;
app.config.globalProperties.$domainURL = application.URL.DEFAULT_DOMAIN;
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(Toaster, {
    position: 'top',
});
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCQuFaWDHVt8s9KwasbHJl7epPt_MEygvE',
    },
});
app.component("vue3-star-ratings", vue3StarRatings);
app.mixin(mixin);
app.use(router).mount('#app');
