<template>
  <div class="contact z-index-2 py-5">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid h-100 z-index-5">
      <div class="row gy-3 h-100 my-0 py-3">
        <div class="col-12 col-md-5">
          <div class="w-100 h-100 d-flex flex-column justify-content-between">
            <h2
              class="contact-section-heading"
              :style="{color: componentUi.headerFontColor}"
            >{{contactData.title}}</h2>
            <div>
              <p class="fs-17 text-uppercase mb-1">{{contactData.subTitle}}</p>
              <p class="ff-rubik fs-20 text-uppercase mb-1">{{contactData.address}}</p>
              <p
                class="ff-rubik fs-23 text-uppercase mb-0 text-site-maroon"
              >{{contactData.contactNo}}</p>
            </div>
          </div>
          <hr class="d-md-none" />
        </div>
        <div class="col-12 col-md-7">
          <form class="w-100 h-100 d-flex flex-column justify-content-between">
            <h4
              class="contact-section-subheading mt-4 mt-md-0"
              :style="{color: componentUi.formTitleFontColor}"
            >
              {{contactData.formTitle}}
              <span
                :style="{color: componentUi.formSubTitleFontColor}"
              >{{contactData.formSubTitle}}.</span>
            </h4>
            <div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label text-uppercase after_star">First Name</label>
                  <input
                    type="text"
                    v-model="firstname"
                    class="form-control contact_input"
                    placeholder="First Name"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label text-uppercase after_star">Last Name</label>
                  <input
                    type="text"
                    v-model="lastname"
                    class="form-control contact_input"
                    placeholder="Last Name"
                    required
                  />
                </div>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col-12 col-sm-6">
                  <label class="form-label text-uppercase after_star">email</label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control contact_input"
                    placeholder="demo@example.com"
                    required
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <label class="form-label text-uppercase after_star">Phone Number</label>
                  <input
                    type="text"
                    v-model="phoneNo"
                    class="form-control contact_input"
                    placeholder="(629) 555-0129"
                    required
                  />
                </div>
              </div>
              <div class="mb-3">
                <label class="form-label">What type of beauty professional are you?</label>
                <select v-model="professionalType" class="form-select contact_input">
                  <option style="display:none" value>Please Select</option>
                  <option
                    v-for="(data,index) in professionalTypes"
                    :key="index"
                    :value="data"
                  >{{data}}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">How can we help you?</label>
                <textarea
                  v-model="description"
                  class="form-control contact_input"
                  rows="4"
                  placeholder="Description"
                ></textarea>
              </div>
              <div class="mb-4 mb-md-0">
                <button
                  type="button"
                  @click="contact"
                  class="btn contact_submit_btn text-uppercase px-5 py-2 rounded-0"
                >Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/contact.js">
</script>

<style scoped>
@import "../css/contact.css";
</style>
