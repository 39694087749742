<template>
  <div :style="{'background-color':componentUi.pageBackgroundColor, 'margin-top':'80px'}">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="custom-container-fluid py-5 mt-5">
      <div
        class="d-flex gap-3 flex-column flex-sm-row justify-content-between align-items-center mb-4"
      >
        <h2
          class="ff-poppins fw-600 fs-23 mb-0"
          :style="{color: componentUi.headerFontColor}"
        >Professional Services</h2>
        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <!-- <span>
            <input type="radio" v-model="category" class="btn-check" name="category" id="category" autocomplete="off"
              value="" checked @change="getAll">
            <label class="btn btn-outline-darkred shadow-none" for="category">All</label>
          </span>-->
          <!-- <span v-for="(data, index) in items" :key="index">
            <input type="radio" v-model="category" class="btn-check" name="category" :id="'category'+data.id"
              autocomplete="off" :value="data.name" @change="filter(data.id)">
            <label class="btn btn-outline-darkred shadow-none ms-2" :for="'category'+ data.id">{{data.name}}</label>
          </span>-->
        </div>
      </div>
      <!-- <vue-picture-swipe :items="items" v-if="items.length > 0" @click="$router.push('/location')"></vue-picture-swipe> -->
      <!-- <div v-else class="card p-3 shadow border-0 round-17" style="background-color: #f2ebd8;">
        <p style="text-align:center;">No Data Found !</p>
      </div>-->

      <div class="row row-cols-1 row-cols-md-4 g-4" v-if="items.length>0">
        <div class="col" v-for="(item, index) in items" :key="index">
          <div
            class="card"
            @click="$router.push(`/sub-services/${item.id}`)"
            style="cursor:pointer"
          >
            <img
              :style="{ height: '300px' ,borderBottom: '1px solid grey'}"
              :src="$imageURL + 'service-category/' + item.image"
              alt="image"
              class="card-img-top img-fluid"
            />
            <div class="card-body">
              <h5 class="card-title text-center ff-poppins fw-600 fs-20">{{ item.name }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info" role="alert" style="text-align:center;">No Data Found!</div>
      </div>
    </div>
  </div>
</template>

<script src="../js/service.js"></script>

<style scoped>
@import "../css/gallery.css";

.btn-outline-darkred {
  --bs-btn-color: #610000;
  --bs-btn-border-color: #610000;
  --bs-btn-hover-color: #610000;
  --bs-btn-hover-bg: #610000;
  --bs-btn-hover-border-color: #610000;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #610000;
  --bs-btn-active-bg: #610000;
  --bs-btn-active-border-color: #610000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #610000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #610000;
  --bs-gradient: none;
}

.btn-outline-darkred:hover {
  color: #fff;
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
  color: #fff;
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.singleItem {
  padding: 20px;
}
/* .singleItem img{
  widows: 100%;
} */
</style>
