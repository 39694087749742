export default {
    name: "SectionOne",
    props:['sectionOne', 'componentUi'],
    methods: {
        scrollToBottom() {
            console.log('Scrolling to top');
            document.querySelector("#p1").scrollIntoView({ behavior: "smooth" })
        },
    },
}
