<template>
  <section class="full_page two position-relative bg-site-gray z-index-2" v-if="sectionTwo.length > 0">
    <div class="custom-container-fluid h-100">
      <h1 class="section_two_heading" :style="{color:componentUi.sectionTwoHeaderFontColor}">{{sectionTwoHeader}}</h1>
      <div class="row gy-4 mt-3">
        <div class="col-12 col-sm-6 col-md-4" v-for="(data, index) in sectionTwo" :key="index">
          <div class="section_two_card">
            <span class="section_two_card_number">{{index + 1}}</span>
            <div class="section_two_card_title" :style="{color:componentUi.sectionTwoSubTitleFontColor}">
              {{data.heading}}
            </div>
            <div>
             <p class="section_two_card_text" :style="{color:componentUi.sectionThreeTitleFontColor}">{{data.description}}</p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="custom_pagination">
      <div class="custom-container-fluid">
        <div class="d-flex align-items-center gap-4">
          <div class="d-flex text-site-dark h-100">
            <span class="active_page h-100 align-self-start">002</span>
            <span class="mx-2 h-100 align-self-start">/</span>
            <span class="h-100 align-self-start">007</span>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void(0)" @click="scrollToBottom" class="btn arrow-down" id="p2">
      <i class="bi bi-arrow-down"></i>
    </a>
  </section>
</template>

<script src="../js/section_two.js">
</script>

<style scoped>
@import "../css/section_two.css";
</style>
