<template>
  <div>
    <div class="sidebar" :class="[store.active ? 'nav-active' : '']">
      <ul class="list-unstyled">
        <li class=" nav__list-item" @click="closeSidebar">
          <router-link tag="a" to="/about" class="sidebar_link">
            ABOUT
          </router-link>
        </li>
        <li class=" nav__list-item" @click="closeSidebar">
          <router-link tag="a" to="/professional-services" class="sidebar_link">
            PROFESSIONAL SERVICES
          </router-link>
        </li>
        <li class=" nav__list-item" @click="closeSidebar">
          <router-link tag="a" to="/location" class="sidebar_link">
            COSMO STUDIOS
          </router-link>
        </li>
        <li class=" nav__list-item" @click="closeSidebar">
          <router-link tag="a" to="/experts" class="sidebar_link">
            COSMO EXPERTS
          </router-link>
        </li>
        <li class=" nav__list-item" @click="closeSidebar">
          <router-link tag="a" to="/contact" class="sidebar_link">
            CONTACT
          </router-link>
        </li>
        <li class="text-center mt-4" @click="closeSidebar">
          <a class="fs-20 text-white btn border-1 border-white" @click="$router.push('/contact')">
            SCHEDULE A FREE TOUR
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script src="../js/sidebar.js"></script>

<style scoped>
@import "../css/sidebar.css";
</style>
