export default {
    name: "SectionThree",
    props: ['sectionThreeHeader', 'sectionThree', 'componentUi'],
    methods: {
        scrollToBottom() {
            console.log('Scrolling to top');
            document.querySelector("#p3").scrollIntoView({ behavior: "smooth" })
        },
    },
}
