import { store } from "@/store";
export default {
    components: {},
    name: "Header",
    data() {
        return {
            store,
            headerCMS: {},
            limitPosition: 60,
            scrolled: false,
            lastPosition: 0
        }
    },
    async created() {
        window.addEventListener("scroll", this.handleScroll);
        await this.getHeaderData();
    },
    methods: {
        openSideDrawer: function () {
            this.store.active = true;
        },
        getHeaderData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.headerCms,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200 && response.data.data.length > 0) {
                    this.headerCMS = response.data.data[0];
                }
            }).catch(error => {
                console.log(error);
            });
        },
        async goHome() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
           await this.$router.push('/')
        },
        handleScroll() {
            if (this.limitPosition < window.scrollY && this.limitPosition < window.scrollY) {
                this.scrolled = true;
                // move up!
            }

            if (this.limitPosition >= window.scrollY) {
                this.scrolled = false;
                // move down
            }

            //this.lastPosition = window.scrollY;
            // this.scrolled = window.scrollY > 250;
        }
    },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
}
};
