<template>
  <section class="full_page four position-relative bg-site-gray z-index-2" v-if="Object.values(sectionFour).length > 0">
    <div class="custom-container h-100">
      <div class="d-flex h-100 align-items-center">
        <div class="d-flex gap-3 flex-column flex-sm-row align-items-center">
          <div class="w-100">
            <h1 class="section_four_heading" :style="{color:componentUi.sectionFourTitleFontColor}">{{sectionFour.title}}</h1>
            <p :style="{color:componentUi.sectionFourSubTitleFontColor}">
              {{sectionFour.subTitle}}
            </p>
          </div>
          <div class="w-100 text-start text-sm-center">
            <button  class="btn section_four_btn"><a :href="sectionFour.buttonLink" style="color:inherit;" target="_blank">{{sectionFour.buttonText}}</a> <i class="bi bi-arrow-right ms-3"></i></button>
          </div>
        </div>
      </div>
    </div>
    <div class="custom_pagination">
      <div class="custom-container-fluid">
        <div class="d-flex align-items-center gap-4">
          <div class="d-flex text-site-dark h-100">
            <span class="active_page h-100 align-self-start">004</span>
            <span class="mx-2 h-100 align-self-start">/</span>
            <span class="h-100 align-self-start">007</span>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void(0)" @click="scrollToBottom" class="btn arrow-down" id="p4">
      <i class="bi bi-arrow-down"></i>
    </a>
  </section>
</template>

<script src="../js/section_four.js">

</script>

<style scoped>
@import "../css/section_four.css";
</style>
