<template>
  <div class="bg-experts-section">
  <section class="experts-section">
    <div class="custom-container-fluid h-100">
      <!-- v-if="Object.values(CMSData).length > 0" -->
      <div class="h-100 d-flex align-items-center">
        <div class="w-100">
          <h1 class="experts-section-heading fs-xs-20">
            <span :style="{color:componentUi.headerFontColor}">{{ CMSData.title }}</span> &nbsp;
            <span :style="{color:componentUi.headerSubTitleColor}">{{ CMSData.redTitle }}</span>
          </h1>

          <p
            class="experts-section-text w-60 w-md-80 w-xs-100 m-auto"
            :style="{color:componentUi.headerDetailsFontColor}"
          >{{ CMSData.description }}</p>
          <div class="experts-section-carousel mt-3" v-if="experts.length > 0">
            <Carousel :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="(data, index) in experts" :key="index">
                <div class="carousel__item">
                  <div class="card w-100 border-0 bg-transparent">
                    <div class="card-img-section position-relative">
                      <a :href="$professional_profile+data.id" target="_blank">
                        <img
                          v-if="data.image"
                          :src="$imageURL + 'profile-image/' + data.image"
                          class
                          alt="image"
                        />
                        <img v-else src="../../../assets/images/default.png" class alt="image" />
                      </a>
                    </div>
                    <div class="card-body">
                      <a :href="$professional_profile+data.id" target="_blank">
                        <h5
                        class="card-title text-light"
                        :style="{color:componentUi.nameTitleFontColor}"
                      >{{data.fullName}}</h5>
                      </a>
                      
                      <!-- <p class="card-text text-white" :style="{color:componentUi.nameSubTitleFontColor}">{{ data.specialIn }}</p> -->
                    </div>
                  </div>
                </div>
              </Slide>

              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>

<script src="../js/experts.js"></script>

<style scoped>
@import "../css/experts.css";
</style>
