import Loading from 'vue-loading-overlay';
export default {
  components:{
    Loading
  },
  name: "Login",
  data(){
    return{
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      bAccount: null,
      password: null,
      showPassword: false,
       }
  },
  async created() {
    await this.getPageTitle();
    document.title = (this.pageTitle?.loginPageTitle ? this.pageTitle?.loginPageTitle : '');
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    login(){
      console.log(this.password)
    }
  }
}