import Loading from "vue-loading-overlay";
export default {
    name: "About",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            aboutData: {},
            componentUi:{}
        }
    },
    async mounted() {
        await this.getPageTitle();
        document.title = (this.pageTitle?.aboutPageTitle ? this.pageTitle?.aboutPageTitle : '')
        await this.getAboutData();
    },
    methods: {
        getAboutData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.home.aboutCms,
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    console.log(response.data)
                    this.aboutData = response.data.data.data[0];
                    this.componentUi = response.data.data.aboutComponentUI[0];
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
}
