import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
    name: "Experts",
    components: {
        Carousel,
        Slide,
        Navigation
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'start',
                wrapAround: true,
                autoplay: 0,
                pauseAutoplayOnHover: true,
            },
            breakpoints: {
                // 700px and up
                575: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },
            CMSData: {},
            items: [],
            experts: [],
            componentUi: {}
        }
    },
    async created() {
        await this.getPageTitle();
        document.title = (this.pageTitle?.expertsPageTitle ? this.pageTitle?.expertsPageTitle : '');
        await this.getExpertCMSData();
        await this.getCosmoExpertsList();
    },
    methods: {

        getCosmoExpertsList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.experts.professionalList + "?userType=professional"
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.experts = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getExpertCMSData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.experts.CMS,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200 && response.data.data.data.length > 0) {
                    this.CMSData = response.data.data.data[0];
                }
                if (response.data.data.cosmoProfessionalComponentUI.length > 0) {
                    this.componentUi = response.data.data.cosmoProfessionalComponentUI[0];
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getExpertData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.experts.requestURL,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200 && response.data.data) {
                    this.items = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
}
