export default {
    name: "SectionFour",
    props:['sectionFour', 'componentUi'],
    methods: {
        scrollToBottom() {
            console.log('Scrolling to top');
            document.querySelector("#p4").scrollIntoView({ behavior: "smooth" })
        },
    },
}
