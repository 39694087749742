export default {
    name: "SectionTwo",
    props:['sectionTwo', 'sectionTwoHeader', 'componentUi'],
    methods: {
        scrollToBottom() {
            console.log('Scrolling to top');
            document.querySelector("#p2").scrollIntoView({ behavior: "smooth" })
        },
    },
}
