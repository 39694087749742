import Loading from "vue-loading-overlay";
import VuePictureSwipe from 'vue3-picture-swipe';

export default {
    name: "Services",
    components: {
        Loading,
        VuePictureSwipe
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            items: [],
            filters: {
                categoryId: ""
            },
            categories: [
            ],
            category: "",
            componentUi: {}
        }
    },
    async created() {
        await this.getPageTitle();
        document.title = (this.pageTitle?.professionalServicesPageTitle ? this.pageTitle?.professionalServicesPageTitle : '');
        await this.getComponentUi();
    },
    async mounted() {
        // await this.getCategories();
        await this.getGalleryData();
    },
    methods: {

        filter: async function (category) {
            this.filters.categoryId = category;
            await this.getGalleryData();
        },
        getAll: async function () {
            this.filters.categoryId = "";
            await this.getGalleryData();
        },
        getCategories: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.gallery.category,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    this.categories = response.data.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getGalleryData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.gallery.galleryAPI,
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    console.log(response.data);
                    this.items = response.data;
                    // this.items = response.data.map(el => {
                    //    return {
                    //     src: this.$imageURL + 'service-category/' + el.image,
                    //     thumbnail: this.$imageURL + 'service-category/' + el.image,
                    //     title: el.title,
                    //     w: 1200,
                    //     h: 1000,
                    //    }
                    // });
                }
            }).catch(error => {
                console.log(error);
            });
        },

        getComponentUi: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.professionals.componentUiURL,
            }
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.componentUi = response.data.data.length > 0 ? response.data.data[0] : {}
                }
            })
        }
    }
}
