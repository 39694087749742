import Loading from "vue-loading-overlay";
import Footer from "@/components/layouts/template/Footer";
import { store } from "@/store";
import SectionOne from "../template/SectionOne";
import SectionTwo from "../template/SectionTwo";
import SectionThree from "../template/SectionThree";
import SectionFour from "../template/SectionFour";
import SectionFive from "../template/SectionFive";
import SectionSix from "../template/SectionSix";
import SectionSeven from "../template/SectionSeven";
export default {
  name: "home",
  components: {
    Loading,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive,
    SectionSix,
    SectionSeven,
    Footer
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: 'bars',
      store,
      sectionOne: {},
      sectionTwo: {},
      sectionThree: {},
      sectionFour: {},
      sectionFive: {},
      sectionSix: {},
      sectionSeven: {},
      review: [],
      siteSocialLinks: [],
      footer: {},
      sectionTwoHeader: "",
      sectionThreeHeader: "",
      componentUi: {},
      dataLoaded:false,
    }
  },
  async created() {
    await this.getPageTitle();
    document.title = (this.pageTitle?.homePageTitle ? this.pageTitle?.homePageTitle : '')
    await this.getHomePageData();
  },

  methods: {
    getHomePageData: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.home.requestURL,
      };
      await this.$axios(config).then((response) => {
        if (response.status == 200) {
          this.sectionOne = response.data.sectionOneCms;
          this.sectionTwo = response.data.sectionTwoCms;
          this.sectionThree = response.data.sectionThreeCms;
          this.sectionFour = response.data.sectionFourCms;
          this.sectionFive = response.data.sectionFiveCms;
          this.sectionSix = response.data.sectionSixCms;
          this.review = response.data.review;
          this.siteSocialLinks = response.data.siteSocialLinks;
          this.footer = response.data.footerCms;
          this.sectionTwoHeader = response.data.sitecms.sectionTwoHeader;
          this.sectionThreeHeader = response.data.sitecms.sectionThreeHeader;
          this.componentUi = response.data.homeUIComponent;
          this.dataLoaded = true;
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
};
