<template>
  <section
    class="full_page five position-relative z-index-2" :data-visual-label="sectionFive.backgroundTitleOne" :data-visual-label-2="sectionFive.backgroundTitleTwo"
    v-if="Object.values(sectionFive).length > 0"
    id="five-back"
  >
    <div class="custom-container-fluid ">
      <h1
        class="section_five_heading"
        :style="{color:componentUi.sectionFiveHeaderFontColor}"
      >{{sectionFive.title}}</h1>
      <div class="mt-5" v-if="review.length > 0">
        <Carousel :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="(data, index) in review" :key="index">
            <div class="carousel__item">
              <div class="section_five_carousel_card">
                <vue3-star-ratings
                  v-model="data.rating"
                  :showControl="false"
                  :starSize="'20'"
                  :disableClick="true"
                  :inactiveColor="'#888888'"
                  :starColor="'#F22104'"
                />
                <h3
                  class="section_five_carousel_card_heading"
                  :style="{color:componentUi.sectionFiveTitleFontColor}"
                >{{data.heading}}</h3>
                <p
                  class="section_five_carousel_card_text fs-md-18 fs-sm-16"
                  :style="{color:componentUi.sectionFiveSubTitleFontColor}"
                >{{data.description}}</p>
                <div class="d-flex gap-3 align-items-center">
                  <img
                    class="section_five_carousel_card_client_img"
                    :src="$imageURL + 'reviwer-image/' + data.reviewerImage"
                  />
                  <div>
                    <h4 class="section_five_carousel_card_client_name">{{data.reviewerName}}</h4>
                    <h6
                      class="section_five_carousel_card_client_professional"
                    >{{data.reviewerProfessional}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <pagination />
          </template>
        </Carousel>
      </div>
    </div>
    <div class="custom_pagination">
      <div class="custom-container-fluid">
        <div class="d-flex align-items-center gap-4">
          <div class="d-flex text-white h-100">
            <span class="active_page h-100 align-self-start">005</span>
            <span class="mx-2 h-100 align-self-start">/</span>
            <span class="h-100 align-self-start">007</span>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void(0)" @click="scrollToBottom" class="btn arrow-down" id="p5">
      <i class="bi bi-arrow-down"></i>
    </a>
  </section>
</template>

<script src="../js/section_five.js">
</script>

<style scoped>
@import "../css/section_five.css";
</style>
