<template>
  <div class="custom-container-fluid py-5 mt-5 " style="min-height: 100vh">
    <div
      class="d-flex gap-3 flex-column flex-sm-row justify-content-between align-items-center mb-4"
    >
      <h2 class="ff-poppins fw-600 fs-23 mb-0">Service Experts</h2>
      <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
        <!-- <div>
          <span v-for="(data, index) in items" :key="index">
            <input
              type="radio"
              class="btn-check"
              name="category"
              :id="'category'+data.id"
              v-model="id"
              autocomplete="off"
              :value="data.id"
              @change="filter(data.id)"
            />
            <label
              class="btn btn-outline-darkred shadow-none ms-2 w-100"
              :for="'category'+ data.id"
            >{{data.name}}</label>
          </span>
        </div> -->
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-6 g-4" v-if="professionals.length > 0">
      <div class="col professionalsCard" v-for="(item, index) in professionals" :key="index">
        <a target="_blank" :href="($professional_profile + item.professionalId)">
          <div class="card">
            <img
              v-if="item.professionalImage"
              :src="$imageURL + 'profile-image/' + item.professionalImage"
              alt="image"
              class="card-img-top img-fluid"
              style="height:206px;"
            />

            <img v-else :src="defaultImage" alt="image" class="card-img-top img-fluid cardImage" />

            <div class="card-body">
              <h5 class="card-title text-center ff-poppins fw-600 fs-17">{{ item.professionalName }}</h5>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div v-else>
        <div class="alert alert-info" role="alert" style="text-align:center;">No Data Found!</div>
      </div>
  </div>
</template>

<script src="../js/serviceProfessional.js"></script>

<style scoped>
@import "../css/serviceProfessional.css";
.btn-outline-darkred {
  --bs-btn-color: #610000;
  --bs-btn-border-color: #610000;
  --bs-btn-hover-color: #610000;
  --bs-btn-hover-bg: #610000;
  --bs-btn-hover-border-color: #610000;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #610000;
  --bs-btn-active-bg: #610000;
  --bs-btn-active-border-color: #610000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #610000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #610000;
  --bs-gradient: none;
}

.btn-outline-darkred:hover {
  color: #fff;
}

.btn-check:checked + .btn,
.btn-check:active + .btn,
.btn:active,
.btn.active,
.btn.show {
  color: #fff;
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.singleItem {
  padding: 20px;
}
/* .singleItem img{
  widows: 100%;
} */
</style>
