<template>
  <section class="full_page one position-relative bg-white z-index-2" v-if="Object.values(sectionOne).length > 0">
   <div class="custom-container-fluid h-100 d-flex align-items-center">
     <div class="text-center w-100">
       <h1 class="section_one_heading mt-4" :style="{color:componentUi.sectionOneTitleFontColor}">
         {{sectionOne.title}}
         <span class="text-site-darkRed fw-800">PROFESSIONAL</span>
       </h1>
       <p class="w-85 w-sm-100 mx-auto my-4 text-site-dark fs-18" :style="{color:componentUi.sectionOneSubTitleFontColor}">
         {{sectionOne.subTitle}}
       </p>
       <div class="d-flex align-items-center gap-2 gap-md-5 justify-content-center">
         <button class="btn bg-site-maroon rounded-pill text-white border-0 px-5 py-3 ff-rubik"><a :href="$clientSignInURL" style="color:inherit;">{{sectionOne.buttonOneText}}</a></button>
         <!-- <a :href="$clientSignUpURL" class="text-site-dark">{{sectionOne.buttonTwoText}}
           <i class="bi bi-arrow-up-right-square ms-2"></i>
         </a> -->
       </div>
     </div>
   </div>
    <div class="custom_pagination">
      <div class="custom-container-fluid">
        <div class="d-flex align-items-center gap-4">
          <div class="d-flex text-white h-100">
            <span class="active_page text-site-dark h-100 align-self-start">001</span>
            <span class="mx-2 text-site-dark h-100 align-self-start">/</span>
            <span class="h-100 text-site-dark align-self-start">007</span>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:void(0)" @click="scrollToBottom" class="btn arrow-down" id="p1">
      <i class="bi bi-arrow-down"></i>
    </a>
  </section>
</template>

<script src="../js/section_one.js">
</script>

<style scoped>
@import "../css/section_one.css";
</style>
