import { store } from "../../../store";
import Mixin from "../../../mixin"
export default {
  name: "Sidebar",
  mixins: [Mixin],
  data() {
    return {
      store,
    };
  },
  methods: {
    closeSidebar: function () {
      this.store.active = false;
    },
  },
};
