<template>
  <section class="full_page seven position-relative" :style="{'background': `url(${$imageURL}footer-cms/${footer.backgroundImage}) no-repeat center`, 'background-size': 'cover'}" v-if="Object.values(footer).length > 0">
    <div class="custom-container-fluid">
      <p class="section_seven_sub_heading" :style="{color:componentUi.sectionSevenHeaderFontColor}">{{footer.title}}</p>
      <h1 class="section_seven_heading" :style="{color:componentUi.sectionSevenTitleFontColor}">{{footer.subTitle}}</h1>
      <div class="text-center">
        <button class="section_seven_btn px-4 py-1"><a :href="footer.buttonLink" target="_blank" style="color:inherit;">{{footer.buttonText}}</a></button>
      </div>
      <div class="text-center text-white mt-4">
        <span :style="{color:componentUi.sectionSevenSubTitleFontColor}">{{footer.footerInfo}}</span>
      </div>
      <div class="text-center mt-5">
        <a class="me-0 me-sm-3 w-xs-100" href=""><img :src="$imageURL + 'footer-cms/' + footer.playStoreImage" class="w-xs-100"></a>
        <a class="ms-0 ms-sm-3 w-xs-100" href=""><img :src="$imageURL + 'footer-cms/' + footer.appStoreImage" class="w-xs-100 mt-3 mt-sm-0"></a>
      </div>
      <div class="text-center mt-4">
        <a :href="data.siteLink" target="_blank" class="social_btn" v-for="(data, index) in siteSocialLinks" :key="index"><i :class="data.siteIconClass"></i></a>
      </div>
    </div>
    <div class="custom_pagination">
      <div class="custom-container-fluid">
        <div class="d-flex align-items-center flex-column flex-sm-row h-100 gap-4">
          <div class="text-white d-none d-md-flex">
            <span class="active_page h-100 align-self-start">007</span>
            <span class="mx-2 h-100 align-self-start">/</span>
            <span class="h-100 align-self-start">007</span>
          </div>
          <!-- <div class="d-flex w-100 justify-content-center gap-4 text-white fs-xs-16">
            <a>About</a>
            <a>Careers</a>
            <a>Terms of Use</a>
            <a>Privacy Policy</a>
            <a>Contact</a>
          </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script src="../js/section_seven.js">
</script>

<style scoped>
@import "../css/section_seven.css";
</style>
