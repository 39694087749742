<template>
  <div class="login_page">
    <div class="login_page_form_section">
      <div class="text-center mb-4">
        <h3 class="text-site-dark text-uppercase ff-poppins fw-700">
          Cosmo
          <span class="text-site-maroon">Login</span>
        </h3>
      </div>
      <hr />
      <div class="text-center mb-4">
        <h1 class="text-site-dark text-uppercase ff-poppins fw-700">
          Whichever
          <span class="text-site-maroon">
            you
            choose
          </span>
        </h1>
        <p>
          Be your own boss, grow your business, maximize your income and stay in control with automated appointment
          scheduling, free same business day deposits, client management
          <br />and built-in marketing tools
        </p>
      </div>
      <div class="row g-4 justify-content-center">
        <div class="col-6 col-sm-6">
          <a :href="$clientSignInURL" style="text-decoration: none; color:black" target="_blank">
            <div class="card choose_card p-3 align-items-center justify-content-center">
              <div class="text-center">
                <a href="javascript:void(0)" style="color: inherit;">
                  <img src="../../../assets/images/User1.svg" width="60" height="60" />
                  <p class="mb-0 fs-20 ff-poppins fw-500">Client</p>
                </a>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6 col-sm-6">
          <a
            :href="$professionalSignInURL"
            style="text-decoration: none; color:black"
            target="_blank"
          >
            <div class="card choose_card p-3 align-items-center justify-content-center">
              <div class="text-center">
                <a href="javascript:void(0)" style="color: inherit;">
                  <img src="../../../assets/images/User2.svg" width="60" height="60" />
                  <p class="mb-0 fs-20 fs-md-14 ff-poppins fw-500 text-uppercase">Professionals</p>
                </a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/Signin.js">
</script>

<style scoped>
@import "../../../assets/css/login.scss";

.choose_card {
  width: 100%;
  height: 100%;
  transition: all 3s ease;
  cursor: pointer;
}

.choose_card:hover {
  background: var(--site-darkRed) !important;
  color: #ffffff !important;
}
</style>