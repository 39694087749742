export default {
    name: "SectionSix",
    data(){
        return{
            sixBack: 'cosmo',
        }
        
    },
    props:['sectionSix', 'componentUi'],
    methods: {
        scrollToBottom() {
            console.log('Scrolling to top');
            document.querySelector("#p6").scrollIntoView({ behavior: "smooth" });
        },
    },
}
