import defaultImage from "../../../assets/images/default.png"
export default {
  name: "serviceProfessional",
  data() {
    return {
      defaultImage,
      professionals: [],
      items: [],
      id: this.$route.params.id,
    };
  },
  async created(){
    await this.getPageTitle();
    document.title = (this.pageTitle?.expertsPageTitle ? this.pageTitle?.expertsPageTitle : '')
    this.getGalleryData();
  },
  async mounted() {
    console.log(this.$route.params.id)
    await this.getProfessionalsList()
  },
  methods: {
    getProfessionalsList: async function () {
      let config = {
        method: "GET",
        url: this.$serverURL + this.$api.professionals.professionalListURL + "/" + this.id,
      };
      await this.$axios(config).then((response) => {
        if (response.status == 200) {
          console.log(response.data);
          this.professionals = response.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    // sub: async function () {
    //   let config = {
    //     method: "GET",
    //     url: this.$serverURL + this.$api.gallery.galleryAPI,
    //   };
    //   await this.$axios(config).then((response) => {
    //     if (response.status == 200) {
    //       console.log(response.data);
    //       this.items = response.data;
    //       // this.items = response.data.map(el => {
    //       //    return {
    //       //     src: this.$imageURL + 'service-category/' + el.image,
    //       //     thumbnail: this.$imageURL + 'service-category/' + el.image,
    //       //     title: el.title,
    //       //     w: 1200,
    //       //     h: 1000,
    //       //    }
    //       // });
    //     }
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // },
    // filter: async function(id){
    //   this.id = id;
    //   this.getProfessionalsList();
    // }
  },
  
};
