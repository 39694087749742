<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <div class="scroll-snap" v-if="dataLoaded">
      <SectionOne :sectionOne="sectionOne" :componentUi="componentUi" />
      <SectionTwo
        :sectionTwo="sectionTwo"
        :sectionTwoHeader="sectionTwoHeader"
        :componentUi="componentUi"
      />
      <SectionThree
        :sectionThree="sectionThree"
        :sectionThreeHeader="sectionThreeHeader"
        :componentUi="componentUi"
      />
      <SectionFour :sectionFour="sectionFour" :componentUi="componentUi" />
      <SectionFive :sectionFive="sectionFive" :review="review" :componentUi="componentUi" />
      <SectionSix :sectionSix="sectionSix" :componentUi="componentUi" />
      <SectionSeven :footer="footer" :siteSocialLinks="siteSocialLinks" :componentUi="componentUi" />
      <div class="full_page d-flex align-items-center p-0">
        <Footer class="w-100"/>
      </div>
    </div>
  </div>
</template>

<script src="../js/home.js"></script>

<style scoped>
@import "../css/home.css";
</style>


