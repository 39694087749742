<template>
  <!--header section start-->
  <section class="header fixed-top py-3" :class="[{'bg-transparent':store.active},{'header-color': scrolled},{'bg-site-black': $route.path=== '/about'},{'bg-site-black': $route.path=== '/experts'}]" v-on="handleScroll">
    <div class="custom-container-fluid">
      <div class="d-flex gap-3 align-items-center">
        <div class="text-start w_100 w-sm-auto">
          <a v-if="headerCMS.headerText" @click="goHome" class="navbar-brand text-site-dark fw-bolder fs-20 fs-xs-18 cp"
             :class="[store.active ? 'text-site-ghostWhite' : '', $route.path === '/about' || $route.path === '/experts' ? 'text-site-ghostWhite': '']">
            {{headerCMS.headerText}}
          </a>
        </div>
        <div class="text-center w_100 w-sm-auto d-none d-sm-block">
          <a v-if="headerCMS.appLink" class=" navbar-brand text-site-dark" :class="[store.active ? 'text-site-ghostWhite' : '', $route.path === '/about' || $route.path === '/experts' ? 'text-site-ghostWhite': '']" :href="headerCMS.appLink" target="_blank">{{headerCMS.appText}}</a>
        </div>
        <div class="text-end w-100">
          <a class="btn bg-site-darkRed border-site-darkRed text-white me-2 me-sm-4" @click="$router.push('/choose-user-signin')">Login</a>
          <a class="btn bg-site-darkRed border-site-darkRed text-white me-2 me-sm-4" @click="$router.push('/choose-user-signup')">Signup</a>
          <a @click="store.active =! store.active" class="btn menu shadow-none p-0 border-0" :class="[store.active ? 'nav-active' : '', $route.path === '/about' || $route.path === '/experts' ? 'text-white': '']">
            <span class="menu_icon" :class="[store.active ? 'bg-site-ghostWhite' : '']"></span>
            <span class="menu_icon"></span>
            <span class="menu_icon" :class="[store.active ? 'bg-site-ghostWhite' : '']"></span>
          </a>
        </div>
      </div>
    </div>
  </section>
  <!--header section end-->
</template>

<script src="../js/header.js"></script>

<style scoped>
@import "../css/header.css";
</style>
