<template>
  <div class="location-page ps-2 pe-2 ps-md-5 pe-md-0 pt-5 d-flex flex-column vh-100">
    <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
    <h1 class="text-uppercase ff-rubik ps-2 ps-md-0 pt-3">
      <button @click="isShow =! isShow" class="btn btn-outline-secondary d-sm-none">
        <i v-if="!isShow" class="bi bi-list-ul"></i>
        <i v-else class="bi bi-x-lg"></i>
      </button>
      <span :style="{color:componentUi.headerFontColor}">Studios</span>
    </h1>

    <div class="h-100" v-if="studioList.length>0">
      <div class="row my-0 h-100 gy-3 gy-sm-0">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 h-xs-auto">
          <div class="location-sidebar" :class="isShow ? 'isShow': ''">
            <ul class="list-unstyled">
              <li class="mb-3" v-for="(data, index) in studioList" :key="index">
                <div class="card">
                  <div class="p-2 pb-0">
                    <img
                      class="w-100 rounded-1"
                      :src="$imageURL + 'studio/' + data.image"
                      height="150"
                    />
                  </div>
                  <div class="card-body">
                    <div class="d-flex gap-3 align-items-center mb-2">
                      <i class="bi bi-shop text-site-maroon fs-18"></i>
                      <h4
                        class="mb-0 w-100 fs-18"
                        :style="{color:componentUi.studioNameFontColor}"
                      >{{data.studioName}}</h4>
                    </div>
                    <div class="d-flex gap-3 align-items-center">
                      <i class="bi bi-geo-alt-fill text-site-maroon fs-18"></i>
                      <p
                        class="mb-0 w-100 fs-13 mb-0"
                        :style="{color:componentUi.locationNameFontColor}"
                      >{{data.locationName}}</p>
                    </div>
                    <div class="d-flex gap-3 align-items-center">
                      <i class="bi bi-envelope-fill text-site-grayish fs-18"></i>
                      <p
                        class="mb-0 w-100 fs-13 mb-0"
                        :style="{color:componentUi.emailFontColor}"
                      >{{data.email}}</p>
                    </div>
                    <div class="d-flex gap-3 align-items-center">
                      <i class="bi bi-telephone-fill text-site-grayish fs-18"></i>
                      <p
                        class="mb-0 w-100 fs-13 mb-0"
                        :style="{color:componentUi.contactFontColor}"
                      >{{data.contactNo}}</p>
                    </div>
                    <div class="d-flex gap-3 align-items-center mb-2">
                      <i class="bi bi-clock text-site-grayish fs-18"></i>
                      <p
                        class="mb-0 w-100 fs-13 mb-0"
                        :style="{color:componentUi.descriptionFontColor}"
                      >{{data.description}}</p>
                    </div>
                    <div>
                      <button
                        class="btn w-100 bg-site-maroon text-white border-0"
                        @click="setMapLink(data)"
                      >See location</button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-8 col-lg-9 h-100">
          <div class="h-100 w-100">
            <GMapMap :center="center" :zoom="zoomValue" style="width: 876px; height: 736px">
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="openMarker(m.id)"
              >
                <GMapInfoWindow
                  :closeclick="true"
                  @closeclick="openMarker(null)"
                  :opened="openedMarkerID === m.id"
                >
                  <div style="display: flex; flex-direction:column; align-item:center">
                    <h6>Studio: {{m.studioName}}</h6>
                    <h6>Location: {{m.locationName}}</h6>
                  </div>
                </GMapInfoWindow>
              </GMapMarker>
            </GMapMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/location.js">
</script>

<style scoped>
@import "../css/location.css";
</style>
