import Loading from "vue-loading-overlay";
export default {
    name: "Contact",
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            loader: 'bars',
            contactData: {},
            firstname: '',
            lastname: '',
            email: '',
            phoneNo: '',
            professionalType: '',
            description: '',
            professionalTypes: [],
            componentUi: {}
        }
    },
    async mounted() {
        await this.getPageTitle();
        document.title = (this.pageTitle?.contactPageTitle ? this.pageTitle?.contactPageTitle : '');
        await this.getContactPageData();
    },
    methods: {
        getContactPageData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.contact.contactCms,
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.contactData = response.data.data.data[0];
                    this.professionalTypes = this.contactData.professionalType.split(',');
                    this.componentUi = response.data.data.contactComponentUI.length>0 ? response.data.data.contactComponentUI[0] : {};
                    console.log(this.componentUi);
                }
            }).catch(error => {
                console.log(error);
            });
        },
        checkValidation: function () {
            if (!this.firstname) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter firstname"
                });
                return false
            }
            if (!this.lastname) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter lastname"
                });
                return false
            }
            if (!this.phoneNo) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter phone number"
                });
                return false
            }
            if (!this.professionalType) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please select professionalType"
                });
                return false
            }
            if (!this.description) {
                this.$swal.fire({
                    icon: "info",
                    title: "Please enter description"
                });
                return false
            }
            return true;
        },
        contact: async function () {
            if (this.checkValidation()) {
                let data = {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    phoneNo: this.phoneNo,
                    professionalType: this.professionalType,
                    description: this.description
                }
                let config = {
                    method: "POST",
                    data: data,
                    url: this.$serverURL + this.$api.contact.contactAPI,
                };
                this.isLoading = true;
                await this.$axios(config).then((response) => {
                    this.isLoading = false;
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.firstname = "";
                        this.lastname = "";
                        this.email = "";
                        this.phoneNo = "";
                        this.professionalType = "";
                        this.description = "";
                    }
                }).catch(error => {
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "info",
                        title: error.response.data.message
                    });
                });
            }

        },
    }
}
